<header class="header-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="header">
          <a
            class="logo-container"
            routerLink="/"
            [class.white]="router.url !== '/'"
          ></a>
          <ul class="navigation-items">
            <li>
              <a
                routerLink="/"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Home
              </a>
              <a routerLink="/services" [routerLinkActive]="'is-active'">
                Services
              </a>
              <a routerLink="/projects" [routerLinkActive]="'is-active'">
                Projects
              </a>
              <a routerLink="/contact-us" [routerLinkActive]="'is-active'">
                Contact
              </a>
            </li>
          </ul>

          <div id="nav-icon3" [class.open]="isMenuOpen" (click)="openMenu()">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div id="myNav" class="overlay" [class.w-100]="isMenuOpen">
            <div class="overlay-content">
              <a
                routerLink="/"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="openMenu()"
              >
                Home
              </a>
              <a
                routerLink="/services"
                [routerLinkActive]="'is-active'"
                (click)="openMenu()"
              >
                Services
              </a>
              <a
                routerLink="/projects"
                [routerLinkActive]="'is-active'"
                (click)="openMenu()"
              >
                Projects
              </a>
              <a
                routerLink="/contact-us"
                [routerLinkActive]="'is-active'"
                (click)="openMenu()"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
