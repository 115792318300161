<div class="partners-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="partners">
          <div class="about-partners-container">
            <div class="our-services">
              <div class="mb-5">
                <h2>
                  Let’s work together
                  <lottie-player
                    class="lottie-container"
                    src="../../assets/animated_arrow.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  >
                  </lottie-player>
                </h2>
              </div>
              <div class="btn-container">
                <button
                  type="button"
                  class="btn btn-gradient mr-lg-4"
                  data-aos="fade-up"
                >
                  Start a Project
                </button>
                <span class="my-3" data-aos="fade-up">or</span>
                <button
                  type="button"
                  class="btn btn-transparent ml-lg-4"
                  data-aos="fade-up"
                >
                  info@crystal.code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
