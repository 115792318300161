<div class="details-container">
  <div class="container-fluid">
    <div class="details">
      <div class="title-container" data-aos="fade-up">
        <h3 class="mb-3">
          Prize games can increase business success <br />
          in a number of ways
        </h3>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2 mb-sm-5" data-aos="fade-up">
          <h6 class="mb-3">Attracting new customers:</h6>
          <div class="d-flex mt-2">
            <div class="mr-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L10 18L20 6"
                  stroke="#50FAAB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>

            <p>
              Prize games can be a great way to attract new customers to your
              business. People are often drawn to the chance of winning
              something, and a prize game can be an effective way to get
              potential customers through your doors.
            </p>
          </div>
        </div>

        <div class="col-md-6 mb-2 mb-sm-5" data-aos="fade-up">
          <h6 class="mb-3">Building customer loyalty:</h6>
          <div class="d-flex mt-2">
            <div class="mr-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L10 18L20 6"
                  stroke="#50FAAB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>

            <p>
              Prize games can also be a way to build customer loyalty. If you
              offer regular prize games, your customers may keep coming back to
              your business in the hopes of winning a prize. This can help you
              build a base of loyal customers who are more likely to recommend
              your business to others.
            </p>
          </div>
        </div>

        <div class="col-md-6 mb-2 mb-sm-5" data-aos="fade-up">
          <h6 class="mb-3">Generating social media buzz:</h6>
          <div class="d-flex mt-2">
            <div class="mr-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L10 18L20 6"
                  stroke="#50FAAB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>

            <p>
              Prize games can be a great way to generate buzz on social media.
              If you promote your prize game on social media, your followers may
              share your post with their friends and family, increasing the
              reach of your promotion. Increasing sales: Prize games can also
              increase sales, especially if you require customers to make a
              purchase to enter the game. This can help boost your revenue and
              improve your bottom line.
            </p>
          </div>
        </div>

        <div class="col-md-6 mb-2 mb-sm-5" data-aos="fade-up">
          <h6 class="mb-3">Collecting customer data:</h6>
          <div class="d-flex mt-2">
            <div class="mr-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L10 18L20 6"
                  stroke="#50FAAB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>

            <p>
              Prize games can also be a way to collect customer data, such as
              email addresses or phone numbers. This information can be valuable
              for future marketing efforts and can help you build a database of
              interested customers.
            </p>
          </div>
        </div>

        <!-- <p class="text-center">
          Overall, prize games can be a fun and effective way to boost your
          business success. However, it's important to ensure that your prize
          games are legal and comply with any relevant regulations.
        </p> -->
      </div>
    </div>
  </div>
</div>
