import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bjelovar-gastro-page',
  templateUrl: './bjelovar-gastro-page.component.html',
  styleUrls: ['./bjelovar-gastro-page.component.scss'],
})
export class BjelovarGastroPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
