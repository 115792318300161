<div class="our-services-page-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="our-services-page">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div
                class="d-flex flex-column align-items-start justify-content-center h-100"
              >
                <div class="title-container">
                  <h1 class="my-4">What We Do</h1>
                  <h6 class="gray mt-3 mb-5 font-weight-400">
                    We blend creativity and technical expertise to provide
                    tailored solutions that drive business growth and user
                    engagement. Explore how we can transform your vision into
                    digital success.
                  </h6>
                </div>
                <button
                  type="button"
                  class="btn btn-gradient w-auto"
                  data-aos-delay="250"
                  routerLink="/projects"
                >
                  Our Work
                </button>
              </div>
            </div>
            <div class="col-lg-6">
              <lottie-player
                class="lottie-container"
                src="../../assets/services.json"
                background="transparent"
                speed="0.7"
                loop
                autoplay
              >
              </lottie-player>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="our-services">
            <div class="row">
              <div class="service-container">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="service-box box-1" data-aos="slide-up">
                        <h3 class="mb-4">Web Development</h3>
                        <p class="gray">
                          We take a user-centered approach, combining our
                          technical prowess with a deep understanding of your
                          target audience to create websites that not only
                          function flawlessly but also resonate with your users'
                          needs and preferences.
                        </p>
                        <p class="gray">
                          Whether it's a corporate website, e-commerce platform,
                          or a custom web application, we ensure it meets your
                          business objectives.
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="service-box box-2" data-aos="slide-up">
                        <h3 class="mb-4">Mobile Development</h3>
                        <p class="gray">
                          Our Ionic-based mobile apps are not only
                          cross-platform but also highly efficient and scalable.
                          We pay meticulous attention to the mobile user
                          experience, optimizing performance and ensuring
                          seamless integration.
                        </p>
                        <p class="gray">
                          Whether it's iOS or Android, we deliver
                          high-performance apps tailored to your industry. Your
                          users will enjoy a consistent, top-tier experience on
                          any device.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="service-container">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="service-box box-3" data-aos="slide-up">
                        <h3 class="mb-4">Game development</h3>
                        <p class="gray">
                          We thrive on pushing the boundaries of creativity and
                          technology in game development. Our Unity-powered
                          games are not just entertaining but also immersive,
                          with engaging storylines and captivating gameplay.
                        </p>

                        <p class="gray">
                          Whether it's a mobile game, AR/VR experience, or
                          console game, we make sure it stands out in the
                          competitive gaming industry.
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="service-box box-4" data-aos="slide-up">
                        <h3 class="mb-4">UX/UI development</h3>
                        <p class="gray">
                          Figma is our tool of choice for creating designs that
                          are not only visually stunning but also highly
                          functional. Our UX/UI development process involves
                          thorough user research, wireframing, and prototyping,
                          ensuring that your digital products are intuitive and
                          conversion-focused.
                        </p>
                        <p class="gray">
                          We design with your users in mind, resulting in
                          interfaces that drive user engagement and
                          satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-contact-us></app-contact-us>
        </div>
      </div>
    </div>
  </div>
</div>
