import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { CocaColaPageComponent } from './coca-cola-page/coca-cola-page.component';
import { OkPlayPageComponent } from './ok-play-page/ok-play-page.component';
import { CedevitaPageComponent } from './cedevita-page/cedevita-page.component';
import { BjelovarGastroPageComponent } from './bjelovar-gastro-page/bjelovar-gastro-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { OurServicesPageComponent } from './our-services-page/our-services-page.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { animation: 'HomePage' } },
  {
    path: 'project/cedevita',
    component: CedevitaPageComponent,
    data: { animation: 'ProjectPage' },
  },
  {
    path: 'project/ok-play',
    component: OkPlayPageComponent,
    data: { animation: 'ProjectPage' },
  },
  {
    path: 'project/coca-cola',
    component: CocaColaPageComponent,
    data: { animation: 'ProjectPage' },
  },
  {
    path: 'project/bjelovar-gastro',
    component: BjelovarGastroPageComponent,
    data: { animation: 'ProjectPage' },
  },
  {
    path: 'services',
    component: OurServicesPageComponent,
    data: { animation: 'OurServicesPage' },
  },
  {
    path: 'projects',
    component: ProjectsPageComponent,
    data: { animation: 'ProjectsPage' },
  },
  {
    path: 'contact-us',
    component: ContactUsPageComponent,
    data: { animation: 'ContactUsPage' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
