<div class="partners-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="partners">
          <div class="about-partners-container text-center">
            <div class="our-services">
              <h4 data-aos="fade-up">Reward your customers</h4>
              <h2 class="linear-gradient font-weight-700" data-aos="fade-up">
                BOOOST YOUR SALES
              </h2>
              <!-- <a routerLink="/services" class="see-more" data-aos="fade-up">See more</a> -->
              <div class="services-container">
                <div class="row">
                  <div class="col-12 col-sm-6 col-lg-3">
                    <div class="service-box box-1" data-aos="slide-up">
                      <h5>1. Step</h5>
                      <p class="mt-3">
                        Order or buy a product, Lorem ipsum dolor sit, amet
                        consectetur adipisicing elit. Illo nihil labore modi,
                        nemo distinctio eveniet veniam?
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-3">
                    <div
                      class="service-box box-2"
                      data-aos="slide-up"
                      data-aos-delay="50"
                    >
                      <h5>2. Step</h5>
                      <p class="mt-3">
                        Find the code on the product packing, Lorem ipsum dolor
                        sit, amet consectetur adipisicing elit. Illo nihil
                        labore modi, nemo distinctio eveniet veniam?
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-3">
                    <div
                      class="service-box box-3"
                      data-aos="slide-up"
                      data-aos-delay="100"
                    >
                      <h5>3. Step</h5>
                      <p class="mt-3">
                        Log in to the web/mobile application and enter the code,
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Illo nihil labore modi, nemo distinctio eveniet
                        veniam?
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-3">
                    <div
                      class="service-box box-4"
                      data-aos="slide-up"
                      data-aos-delay="150"
                    >
                      <h5>4. Step</h5>
                      <p class="mt-3">
                        Follow the announcement of the winner, Lorem ipsum dolor
                        sit, amet consectetur adipisicing elit. Illo nihil
                        labore modi, nemo distinctio eveniet veniam?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <p class="rewarding-text font-weight-400" data-aos="fade-up">
                    Rewarding your customers can be an effective way to boost
                    your sales. It can help increase sales and build customer
                    loyalty. It's important to ensure that your rewards are
                    valuable and relevant to your customers, and that you
                    communicate the rewards effectively to encourage
                    participation.
                  </p>
                  <div class="see-more-container" data-aos="fade-up">
                    <button type="button" class="btn btn-gradient">
                      Find out more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
