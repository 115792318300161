<div class="project-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="project">
          <div class="row">
            <div class="col-sm-6">
              <div class="project-logo-container"></div>
              <div class="title-container">
                <h1>
                  Prize game for one of the most famous and longest-lived brands
                  in the region
                </h1>
              </div>
              <button
                type="button"
                class="btn btn-gradient"
                data-aos-delay="250"
              >
                Read more
              </button>
            </div>
            <div class="col-sm-6">
              <div
                class="services-infographic"
                data-aos="fade-left"
                data-aos-delay="250"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="challange-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="challange">
          <h3 data-aos="fade-up">Challange</h3>
          <div class="info-container" data-aos="fade-up">
            <div class="row">
              <div class="col-sm-12">
                <p class="gray">
                  Obrana jake pozicije i postizanje rasta prodaje, uz izazivače
                  koji pokušavaju oduzeti vodeću poziciju. Potaknuti najmanje
                  50.000 igrača na sudjelovanje u nagradnoj igri, te ih
                  potaknuti da aktiviraju najmanje 500.000 kodova. Ojačati brand
                  Cedevita u HoReCa kanalu u Hrvatskoj i BIH. Povećati snagu
                  ključnih Cedevita brand asocijacija u HoReCa kanalu u u
                  Hrvatskoj i BIH: positive energy, the brand is gaining,
                  available in coffee houses, best taste, refreshing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tech-stack-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="tech-stack" data-aos="fade-up">
          <h3 class="title-mobile" data-aos="fade-up">Tech Stack</h3>
          <div class="info-container">
            <div class="row">
              <div class="col-sm-4">
                <h3>Tech Stack</h3>
              </div>
              <div class="col-sm-8">
                <ul class="tech-list orange">
                  <li>HTML</li>
                  <li>SCSS</li>
                  <li>ANGULAR</li>
                  <li>TYPESCRIPT</li>
                  <li>NODEJS</li>
                </ul>
              </div>
              <div class="col-sm-7"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="strategy-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="strategy">
          <h3 data-aos="fade-up">Strategy</h3>
          <div class="info-container" data-aos="fade-up">
            <div class="row">
              <div class="col-sm-12">
                <p class="gray">
                  Shvatili smo da moramo organizirati nagradnu igru oko jedne
                  sjajne nagrade koja će postati temelj nagradne igre i zvijezda
                  komunikacije. Odlučeno je da će glavna nagrada biti Fiat 500,
                  popularni Fićo, s obzirom da karakteristike Fiće u potpunosti
                  odgovaraju karakteristikama Cedevite. Fićo je legendarni
                  proizvod, zauvijek mlad, nedavno redizajniran, pozitivnog
                  duha, okretan i dinamičan. Uz to riječ je o gradskom autu,
                  koji dolazi u puno boja, idealan auto za urbani životni stil
                  naše ciljne skupine. Slogan kamapanje: Dođi pješke, odvezi se
                  Fićom. Medijska strategija: integrirani media mix (TV, WEB,
                  OOH, RADIO) Web stranica kampanje
                  http://osvojificu.cedevita.com služila je kao baza svih
                  aktivnosti. TV kampanja je zbog dužine trajanja nagradne igre
                  imala zadan što dulji period on air, te je planirana po
                  principu one week on – one week off. OOH – citylights u
                  blizino kafića i u pješačkim zonama za dodatnu vizalizaciju
                  akivacije. Radio – dodatna podrška kampanji za bolji doseg
                  kroz ljetni period i kontinuitet kampanje. Online kampanja je
                  bila niskog intenziteta u dugom periodu – kao nositelji
                  kreative na top portalima (24sata, Jutarnji, Tportal) su
                  korišteni atraktivni formati wallpaper-a i billboard banner,
                  dok je na mobilnoj mreži korišten nenametljiv sticker.
                </p>
              </div>
            </div>
          </div>
          <div class="strategy-infographic"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="results-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="results">
          <h3 data-aos="fade-up">Results</h3>
          <div class="services-container" data-aos="fade-up">
            <div class="row">
              <div class="col-sm-6">
                <p class="gray">
                  U nagradnoj igri u Hrvatskoj i BIH ukupno je sudjelovalo
                  75.485 igrača čime je nadmašen cilj kampanje (51%). U tom
                  periodu igrači su poslali čak 1.070.755 kodova, te je time
                  'Osvoji Fiću u kafiću' postala jedna od najuspješnijih
                  nagradnih igra u povijesti Cedevite. <br />
                  <br />
                  Prodaja je rasla na oba tržišta – u Hrvatskoj za 10%, a u BIH
                  za čak 22%. Cedevita nezaustavljivo raste te se rješava
                  percepcije pića koje se isključivo konzumira u kućanstvu!
                  <br /><br />
                  U odnosu na proteklu godinu, istraživanje provedeno nakon
                  kampanje pokazuje značajno povećanje postotka ljudi koji
                  doživljavaju poželjne asocijacije na brand Cedevita u HoReCa
                  kanalu.
                </p>
              </div>
              <div class="col-sm-6">
                <span class="code-number"
                  >Uneseno <br />
                  1.070.755 kodova
                </span>
              </div>
            </div>
            <div class="project-conclusion-img"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
