<div class="home-container">
  <div class="container-fluid">
    <div class="home">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="title-container">
            <h1 class="my-4">Creating software as valuable as crystals</h1>
            <h6 class="gray mt-3 mb-5 font-weight-400">
              We'll do our best for you. You can always rely on us. We believe
              that your project will make a success and we'll be part of it.
              Your project will be made in the newest technologies on the market
              and work the best for both of us.
            </h6>
          </div>
          <button type="button" class="btn btn-gradient" data-aos-delay="250">
            Services
          </button>
        </div>
        <div class="col-lg-6">
          <lottie-player
            class="lottie-container"
            src="../../assets/mobile.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          >
          </lottie-player>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="our-services-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="our-services">
          <h3 class="white" data-aos="fade-up">Our Services</h3>
          <h5 class="gray font-weight-400" data-aos="fade-up">
            We're everything you're looking for
          </h5>
          <!-- <a routerLink="/services" class="see-more" data-aos="fade-up">See more</a> -->
          <div class="services-container">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-3">
                <div class="service-box box-1" data-aos="slide-up">
                  <h5>Web development</h5>
                  <p class="gray">
                    Angular and Node.js are powerful tools for building web
                    applications. When used together, they provide a full-stack
                    solution for building complex and scalable web applications
                    with a modular architecture.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3">
                <div
                  class="service-box box-2"
                  data-aos="slide-up"
                  data-aos-delay="50"
                >
                  <h5>Mobile development</h5>
                  <p class="gray">
                    Ionic is a powerful framework for building cross-platform
                    mobile apps using web technologies. It can be a great choice
                    for businesses or developers who want to create mobile apps
                    that work across multiple platforms while leveraging
                    existing web development skills.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3">
                <div
                  class="service-box box-3"
                  data-aos="slide-up"
                  data-aos-delay="100"
                >
                  <h5>Game development</h5>
                  <p class="gray">
                    We thrive on pushing the boundaries of creativity and
                    technology in game development. Our Unity-powered games are
                    not just entertaining but also immersive, with engaging
                    storylines and captivating gameplay.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3">
                <div
                  class="service-box box-4"
                  data-aos="slide-up"
                  data-aos-delay="150"
                >
                  <h5>UX/UI development</h5>
                  <p class="gray">
                    Figma is our tool of choice for creating designs that are
                    not only visually stunning but also highly functional. Our
                    UX/UI development process involves thorough user research,
                    wireframing, and prototyping, ensuring that your digital
                    products are intuitive and conversion-focused.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="see-more-container" data-aos="fade-up">
                <button type="button" class="btn btn-gradient">
                  Find out more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-partners></app-partners>
<app-projects></app-projects>
<app-details></app-details>
<app-contact-us></app-contact-us>
