import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-projects-swiper',
  templateUrl: './projects-swiper.component.html',
  styleUrls: ['./projects-swiper.component.scss'],
})
export class ProjectsSwiperComponent implements OnInit {
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,

    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',

      clickable: true,
    },
  };

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  goToProject(projectName: string): void {
    this._router.navigate([`project/${projectName}`]);
  }
}
