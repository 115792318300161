<swiper [config]="config" data-aos="fade-up">
  <div class="swiper-wrapper">
    <div class="swiper-slide" (click)="goToProject('cedevita')">
      <div class="boxes-container">
        <div class="project-card box-1 pointer">
          <div class="overlay"></div>
          <div class="project-image"></div>
          <div class="project-informations">
            <img
              src="../../assets/cedevita-logo.svg"
              height="50px"
              alt="Cedevita"
            />
            <div>
              <span class="project-description">Web app</span>
              <p class="project-title">
                <span>Cedevita Prize Game</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" (click)="goToProject('coca-cola')">
      <div class="boxes-container">
        <div class="project-card box-2 pointer">
          <div class="overlay"></div>
          <div class="project-image"></div>
          <div class="project-informations">
            <img
              src="../../assets/coca-cola-logo.svg"
              height="40px"
              alt="Coca-Cola logo"
            />
            <div>
              <span class="project-description">Mobile app</span>
              <p class="project-title">
                <span>Coca-Cola Prize Game</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" (click)="goToProject('ok-play')">
      <div class="boxes-container">
        <div class="project-card box-3 pointer">
          <div class="overlay"></div>
          <div class="project-image"></div>
          <div class="project-informations">
            <img
              src="../../assets/valora-logo.svg"
              height="30px"
              alt="Valora logo"
            />
            <div>
              <span class="project-description">Web & game app</span>
              <p class="project-title">
                <span>ok.-play Web Game</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" (click)="goToProject('bjelovar-gastro')">
      <div class="boxes-container">
        <div class="project-card box-4 pointer">
          <div class="overlay"></div>
          <div class="project-image"></div>
          <div class="project-informations">
            <img
              src="../../assets/b-gastro-logo.svg"
              height="44px"
              alt="Odsustva logo"
            />
            <div>
              <span class="project-description">Mobile app</span>
              <p class="project-title">
                <span>Bjelovar Food Delivery</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Pagination -->
  <div class="swiper-pagination"></div>
  <!-- Add Arrows -->
  <!-- <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div> -->
</swiper>
