<div class="contact-us-page-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="contact-us-page">
          <div class="row">
            <div class="col-sm-12 my-4">
              <h3>Let’s get your project started!</h3>
              <h5 class="gray font-weight-400">
                We love to talk software development! Complete the form, call
                us, or drop us an email.
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div>
                  <label for="fname">First Name</label>
                  <input
                    type="text"
                    id="fname"
                    formControlName="firstname"
                    placeholder="Your name.."
                  />
                </div>

                <div>
                  <label for="lname">Last Name</label>
                  <input
                    type="text"
                    id="lname"
                    formControlName="lastname"
                    placeholder="Your last name.."
                  />
                </div>

                <div>
                  <label for="subject">Subject</label>
                  <textarea
                    id="subject"
                    formControlName="subject"
                    placeholder="Write something.."
                    style="height: 200px"
                  ></textarea>
                </div>

                <div class="d-flex flex-column">
                  <button type="submit" class="btn btn-gradient mb-4">
                    Send Request
                  </button>

                  <span class="gray small">
                    By clicking "Send request", You agree to Crystal Code
                    <a href="">Privacy Policy.</a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
