<div class="project-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="project">
          <div class="row">
            <div class="col-sm-6">
              <div class="project-logo-container"></div>
              <div class="title-container">
                <h1>
                  Bjelovar Gastro is food delivery service for ordering food.
                </h1>
              </div>
              <button
                type="button"
                class="btn btn-gradient"
                data-aos-delay="250"
              >
                Read more
              </button>
            </div>
            <div class="col-sm-6">
              <div
                class="services-infographic"
                data-aos="fade-left"
                data-aos-delay="250"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="challange-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="challange">
          <h3 data-aos="fade-up">Challange</h3>
          <div class="info-container" data-aos="fade-up">
            <div class="row">
              <div class="col-sm-12">
                <p class="gray">
                  ok.–, die Immobilienmarke der Firma Valora, ist ein trendiger
                  und smarter junger Mann, mobiler Mann. 2018 wird der
                  Detailhändler Valora bald die Menge an Energy Drinks auf dem
                  Markt begrenzen können, auf eine andere fruchtige Art und
                  Weise. Nach dem gleichen Prinzip werden wir im Jahr 2019
                  weitere limitierte Editionen von ok.– Energy Drinks auf den
                  Markt bringen. Diese limitierte Auflage ist uneingeschränkt
                  nutzbar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tech-stack-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="tech-stack" data-aos="fade-up">
          <h3 class="title-mobile" data-aos="fade-up">Tech Stack</h3>
          <div class="info-container">
            <div class="row">
              <div class="col-sm-4">
                <h3>Tech Stack</h3>
              </div>
              <div class="col-sm-8">
                <ul class="tech-list orange">
                  <li>HTML</li>
                  <li>SCSS</li>
                  <li>IONIC</li>
                  <li>TYPESCRIPT</li>
                  <li>NODEJS</li>
                </ul>
              </div>
              <div class="col-sm-7"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="strategy-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="strategy">
          <h3 data-aos="fade-up">Strategy</h3>
          <div class="info-container" data-aos="fade-up">
            <div class="row">
              <div class="col-sm-12">
                <p class="gray">
                  Das Online-Spiel hat ein Comicbuch. Das Spiel enthält auch das
                  Thema der Limited Edition Edition. Die Spieler addieren sich
                  zu den Spielpunkten, die im Softener und direkt in der
                  Kiosk-App oder als SMS-Inhalt verkauft werden. <br />
                  <br />
                  Optional haben wir einen sehr wichtigen Gruppenpreis. Das
                  Online-Spiel hat ein Comicbuch. Das Spiel enthält auch das
                  Thema der Limited Edition Edition. Die Spieler addieren sich
                  zu den Spielpunkten, die im Softener und direkt in der
                  Kiosk-App oder als SMS-Inhalt verkauft werden. Optional haben
                  wir einen sehr wichtigen Gruppenpreis.
                </p>
              </div>
            </div>
          </div>
          <div class="strategy-infographic"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="results-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="results">
          <h3 data-aos="fade-up">Results</h3>
          <div class="services-container" data-aos="fade-up">
            <div class="row">
              <div class="col-sm-6">
                <p class="gray">
                  Ubrzani način života i COVID-19 kriza ponjukale su potrebu za
                  naručivanjem hrane. (Podsjetnik za tekst) Die Optik der
                  Plattform und der Spiele passt hervorragend zum aktuellen
                  Fruchtgetränk der Energy Drinks. Beim Spielen der gemeinsamen
                  Staffel spielt Markerbootmann Belinda Bencic die Plattform und
                  moderiert die Juwelen. <br />
                  <br />
                  Für die Positionierung der POS-Materialien, der Key Visuals
                  und des App-Banners stehen ebenfalls Konzept und Gestaltung
                  der Spielplattform sowie der Spiele und Kompressen komprimiert
                  zur Verfügung.
                </p>
              </div>
              <div class="col-sm-6"></div>
            </div>
            <div class="project-conclusion-img"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
