import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { PartnersComponent } from './partners/partners.component';
import { FooterComponent } from './footer/footer.component';
import { ProjectsComponent } from './projects/projects.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DetailsComponent } from './details/details.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { ProjectsSwiperComponent } from './projects-swiper/projects-swiper.component';
import { OurServicesPageComponent } from './our-services-page/our-services-page.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    OurServicesComponent,
    PartnersComponent,
    FooterComponent,
    ProjectsComponent,
    ContactUsComponent,
    DetailsComponent,
    ContactUsPageComponent,
    ProjectsPageComponent,
    ProjectsSwiperComponent,
    OurServicesPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
