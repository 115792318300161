<div class="projects-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="projects">
          <div class="title-container">
            <h3 class="white" data-aos="fade-up">Our Projects</h3>
            <h5 class="gray font-weight-400" data-aos="fade-up">
              We pour our heart into every project
            </h5>
          </div>
          <!-- <a routerLink="/services" class="see-more" data-aos="fade-up">See more</a> -->

          <app-projects-swiper></app-projects-swiper>
        </div>
      </div>
    </div>
  </div>
</div>
