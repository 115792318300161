import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cedevita-page',
  templateUrl: './cedevita-page.component.html',
  styleUrls: ['./cedevita-page.component.scss'],
})
export class CedevitaPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
