<footer>
  <div class="footer-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="footer">
            <div class="footer-informations">
              <div class="row">
                <div class="col-lg-4">
                  <a class="logo-container" routerLink="/"></a>
                  <!-- <p>
                    Your project is much like a Crystal, one of a kind, unique &
                    special. A piece of the universe, that has a power to
                    impress people.
                  </p> -->
                </div>
                <div class="col-lg-6 mb-3 offset-md-2">
                  <div class="navigation-items-container">
                    <ul class="navigation-items">
                      <li>
                        <a
                          routerLink="/"
                          [routerLinkActive]="'is-active'"
                          [routerLinkActiveOptions]="{ exact: true }"
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a routerLink="/services">Services</a>
                      </li>
                      <li>
                        <a routerLink="/projects">Projects</a>
                      </li>
                      <li>
                        <a routerLink="/contact" class="contact-btn">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row py-4">
              <div class="col-md-6">
                <div class="copyright">
                  © 2020-2023 Crystal Code d.o.o. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
