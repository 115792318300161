<div class="projects-page-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="projects-page">
          <div class="row">
            <div class="col-sm-12 my-4">
              <h2>
                We develop excellent products. <br />
                Let’s develop one for you.
              </h2>
            </div>
          </div>
          <app-projects-swiper></app-projects-swiper>
          <app-contact-us></app-contact-us>
        </div>
      </div>
    </div>
  </div>
</div>
